@import './variables';
@import './mixins';

h1{ font-size: $title-font-size; }
h3{ 
    font-size: $heading-font-size; 
    font-weight: $title-font-weight; 
    text-align: center;
}

.login-background{
    background-image: url("../assets/images/city-background.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position-y: -160px;
    background-position-x: center;
}

.d-inline-block .nav>li>a{
    padding-right: 1.5rem;
}

.fixed-top{
    z-index:900;
}

.btn-login{
    width: 75px;
}

.badge {
    background-color: $primary-color;
    border-color: $primary-hover;
    border-radius: 1rem;
    color: white;
}

.btn-default{
    @include btn-properties($button-font-family, $primary-font-color,  $secondary-button-color, $secondary-button-color);
}

.btn-primary{
    @include btn-properties($button-font-family, $selected-metal, $primary-color, $primary-hover);
}

.btn-secondary{
    @include btn-properties($button-font-family, $selected-metal, $secondary-color, $secondary-hover);
}

.btn-success{
    @include btn-properties($button-font-family, $selected-metal, $accept-color, $accept-hover);
}

.btn-danger{
    @include btn-properties($button-font-family, $selected-metal, $reject-color, $reject-hover);
}

.btn-disabled{
    @include btn-properties($button-font-family, $selected-metal, $disabled-color, $disabled-color);
}

.modal-footer{
    justify-content: space-around;
    border-top: none;
    .row{
        width: 100%;
    }
}

.modal-header{
    background-color: $primary-color;
    color: $selected-metal;
    padding: 7px 15px 10px 15px;
}

.modal-title{
    font-family: $button-font-family;
    font-size: $body-font-size;
    font-weight: $sub-title-font-weight;
}

.modal-content{
    border-color: $primary-color;
}

.btn-primary:focus{
    background-color: $primary-color;
    border-color: $primary-color;
}