@import url(https://fonts.googleapis.com/css?family=Slabo+27px:400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Material+Icons);
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import './variables';
@import './mixins';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import './bootstrap_override.scss';
// @import './angular-theme.scss';
@import './material_override.scss';
@import './pager.scss';

body {
	font-family: $body-font-family;
}

a {
	color: $primary-hover;
}

.pageheight {
	height: 750px;
}

.boxpadding {
	padding-left: 30px;
	padding-right: 30px;
}

.cdk-overlay-container {
	z-index: 2000;
}

.cdk-overlay-container-background {
	background-color: #f6f9fc !important;
}

.error {
	color: $reject-color;
	font-family: $error-font-family;
	font-size: 75%;
	line-height: 1.125;
}

.title-font {
	font-size: $title-font-size;
	font-weight: $title-font-weight;
}

.sub-title-font {
	font-size: $sub-title-font-size;
	font-weight: $sub-title-font-weight;
}

.primary-clr {
	color: $primary-color;
}

.secondary-clr {
	color: $secondary-color;
}

thead tr {
	background-color: $disabled-color;
	color: $selected-metal;
	text-align: left;

	th {
		font-weight: $sub-title-font-weight;
	}

	.font-weight-bold {
		font-weight: bold;
		background-color: black;
	}
}

tbody tr:hover {
	background-color: $accept-color !important;
	cursor: pointer;
	color: $selected-metal;
}

.table-bordered th,
.table-bordered td {
	border: 0px;
}

.modal-dialog {
	width: 50rem;
	height: auto;
	margin: 0px;
}

.fa.fa-usd {
	position: absolute;
	top: 22px;
	left: 13px;
}

.fa.fa-trash {
	color: rgb(217, 83, 79);
}

.fa-download {
	color: $secondary-font-color;
}

.msgFormat {
	white-space: pre-line;
	word-break: break-word;
}

.pos-right {
	position: fixed;
	bottom: 4.4rem;
	right: 15px;
	background-color: #363d44;
	border-radius: 2px;
	color: white;
	padding: 3px 7px;
}

.scrollTop {
	background-color: $secondary-color !important;
	z-index: 1000;
	position: fixed !important;
	bottom: 7rem;
	right: 0.99rem;
}

html {
	scroll-behavior: smooth;
}

.secondary-backgroundclr {
	background-color: $secondary-color;
	color: white;
	font-weight: bolder;
}

.user-msg {
	color: $accept-hover;
}

.hideScrollbar {
	overflow: hidden !important;
	padding: 0px !important;
	margin: 0px !important;
}

.multiLineErr {
	height: auto;
	.fix-mat-form-field {
		.mat-mdc-form-field {
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
			.mat-form-field-wrapper {
				padding-bottom: 0;
				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
				.mat-form-field-underline {
					position: initial !important;
					display: block;
					margin-top: -1px;
				}
				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
				.mat-form-field-subscript-wrapper,
				.mat-form-field-ripple {
					position: initial !important;
					display: table;
				}
				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
				.mat-form-field-subscript-wrapper {
					min-height: calc(1em + 1px);
				}
			}
		}
	}
}

.ulAlign {
	padding-left: 30px;
	text-align: left;
}
html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.dot {
	margin-top: 0;
	margin-bottom: 0 !important;
}

.flex-center-hor {
	display: flex;
	align-items: center;
}

.flex-center-ver {
	display: flex;
	justify-content: center;
}

.mat-icon-24px {
	height: 24px !important;
	width: 24px !important;
	font-size: 24px !important;
}

// Custom styling classes for snackbars
.success-snackbar {
	background-color: #57a850 !important;
	color: white !important;
	font-weight: 500;
	font-size: 15px;
}

.success-snackbar .mat-mdc-snack-bar-action {
	color: white !important;
}

.info-snackbar {
	background-color: #3d4ccd !important;
	color: white !important;
	font-weight: 500;
	font-size: 15px;
}

.info-snackbar .mat-mdc-snack-bar-action {
	color: white !important;
}

.danger-snackbar {
	background-color: #c44939 !important;
	color: white !important;
	font-weight: 500;
	font-size: 15px;
}

.danger-snackbar .mat-mdc-snack-bar-action {
	color: white !important;
}

.wrapInSingleLine {
	white-space: nowrap;
}

.star-size {
	font-size: 1.15em;
	line-height: 0.75em;
	vertical-align: -15%;
}

.star-color {
	color: $secondary-scolor;
  }


.flex-column{
	display: flex;
	flex-direction: column;
}


.table thead th{
    background: rgba(250, 252, 252, 0.9); 
    color: black;
}



.matTimepicker{
    display: flex;
    flex-direction: column;
    label{
        display: flex;
        text-overflow: ellipsis;
        font-size: 11px;
        color: rgba(0, 0, 0, 0.4);
        max-lines: 1;
        white-space: nowrap;
		font-family:'Roboto';
    }
    input{
        border: none;
        border-bottom: 1px solid ;
		border-color: rgba(0, 0, 0, 0.5);
        line-height: 33px;
        outline: none;
		background-color: transparent;
		color: rgba(0, 0, 0, 0.4);
    }
}

:root {
	--mdc-elevated-card-container-shape: 10px;
	--mdc-outlined-button-container-shape: 10px;
	--mdc-protected-button-container-shape: 10px;
	--mdc-elevated-card-container-color: #f8f9fa;
  }
  
  app-header>div>div {
	  margin-left: 35px;
  }
  
  app-home .mat-elevation-z2 {
	  overflow-y: hidden;
  }
  
  .quote-btn, .quote-btn strong, .order-btn {
	  text-shadow: none !important;
  }
  
  .row {
	  margin-right: -5px;
	  margin-left: -5px;
  }
  
  table th {
	  background-color: #727272 !important;
  }
  .table .thead-light th {
	  background-color: #e9ecef !important;
  }
  
  tbody tr:hover {
	  background-color: #cee3eb !important;
	  cursor: pointer;
	  color: #fff;
  }
  
  ul.sidebar li:hover {
	  background-color: #727272 !important;
	  box-shadow: none !important;
	  color: #0f1329;
  }
  ul.sidebar li.active {
	  background-color: #0f1329 !important;
	  box-shadow: none !important;
  }
  
  .unread {
	  background-color: #e8f5fc !important;
	  color: #000;
  }
  
  app-invoice_v2 {
	  
	  .row-end {
		  margin-top: 0 !important;
	  }
	  
	  .mdc-text-field--outlined .mat-mdc-form-field-infix, 
	  .mdc-text-field--no-label .mat-mdc-form-field-infix {
		  padding-bottom: 8px;
	  }
	  
	  app-invoice-due-card mat-card.mat-mdc-card {
		  min-width: 220px !important;
		  margin: 5px !important;
		  box-shadow: 0 .125rem .25rem #00000013 !important;
		  mat-card-title {
			  font-size: 1em;
			  border-top-left-radius: 10px !important;
			  border-top-right-radius: 10px !important;
		  }
	  }
	  .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
		  border-color: rgba(255,255,255,.25);
		  background-color: transparent;
	  }
	  	  
	  app-invoice-due-card:nth-child(1) {
		  mat-card-title {
			  background-color: #de515a !important;
		  }
	  }
	  app-invoice-due-card:nth-child(2) {
		  mat-card-title {
			  background-color: #6ab0d5 !important;
		  }
	  }
	  app-invoice-due-card:nth-child(3) {
		  mat-card-title {
			  background-color: #CCCCCC !important;
		  }
	  }
	  app-invoice-due-card {
		  display: flex;
		  flex-direction: column;
		  flex-basis: 100%;
		  flex: 1;
	  }
  }