@mixin btn-properties($family, $color, $background, $hover) {
    font-family: $family;
    font-size: 0.88rem;
    font-weight: 400;
    text-transform: uppercase;
    color: $color;
    background-color: $background;
    border-color: $hover;
    border-radius: 15px;
    &:hover {
        color: $color;
        background-color: $hover;
        border-color: $hover;
        cursor: pointer;
    }
    &:disabled {
        cursor: not-allowed;
        background-color: $hover;
        border-color: $hover !important;
    }
    &:active {
        background-color: $background !important;
        border-color: $hover !important;
        &:focus {
            box-shadow: 0 0 0 0.2rem $background !important;
        }
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem $background;
    }
    
}
