// @import './variables';
// @import './mixins';


// :root{
// 	--mdc-filled-text-field-container-color: white;
// }

// .mat-mdc-form-field {
// 	width: 100%;
// 	font-family: $body-font-family;
// }

// // .mat-form-field-empty.mat-form-field-placeholder,
// // .mat-error{
// //     text-align: center;
// // }

// // .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-placeholder,
// // .mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-placeholder-wrapper .mat-form-field-placeholder{
// //     text-align: left;
// // }

// // .mat-form-field-appearance-legacy .mat-form-field-label,
// .mat-mdc-form-field-error {
// 	text-align: center;
// }

// /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
// .mat-form-field-empty {
// 	text-align: left;
// }

// /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .mat-form-field-label {
// 	transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
// 		color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
// 		width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
// }

// .mat-icon {
// 	height: 1rem !important;
// 	width: 1rem !important;
// }

// .material-icons {
// 	font-size: 1rem !important;
// }

// .mat-mdc-radio-button {
// 	margin-top: 1rem;
// }

// .mat-calendar-table-header tr {
// 	color: rgba(0, 0, 0, 0.38);
// 	background-color: white;
// }

// .mat-calendar-body tr:hover {
// 	background-color: transparent !important;
// 	cursor: pointer;
// 	color: black;
// }

// .mat-mdc-radio-button {
// 	font-family: $body-font-family;
// }

// /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .dotted .mat-mdc-form-field .mat-form-field-underline {
// 	display: none;
// }

// /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
// .dropDownHide .mat-mdc-form-field .mat-mdc-select.mat-select-disabled .mat-select-arrow {
// 	color: rgba(0, 0, 0, -0.46);
// }

// /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
// .dropDownHide .mat-mdc-form-field .mat-mdc-select.mat-select-disabled .mat-select-value {
// 	color: rgba(0, 0, 0, 0.86);
// }

// .mat-mdc-progress-spinner circle,
// .mat-mdc-progress-spinner circle {
// 	stroke: $primary-color;
// }

// .mat-mdc-progress-spinner.mat-progress-spinner-indeterminate-animation {
// 	animation: mat-progress-spinner-linear-rotate 2s linear infinite !important;
// }

// .mat-mdc-progress-spinner.mat-progress-spinner-indeterminate-animation circle {
// 	transition-property: stroke !important;
// 	animation-duration: 4s !important;
// 	animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1) !important;
// 	animation-iteration-count: infinite !important;
// }

// .mat-datepicker-toggle{
// 	color: black !important;
// }

// /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
// .dropDownHide .mat-form-field-appearance-legacy .mat-form-field-underline{
//     display: none
// }

@import './variables';
@import './mixins';

.mat-mdc-form-field {
  width: 100%;
  font-family: $body-font-family;
}

// .mat-form-field-empty.mat-form-field-placeholder,
// .mat-error{
//     text-align: center;
// }

// .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-placeholder,
// .mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-placeholder-wrapper .mat-form-field-placeholder{
//     text-align: left;
// }

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-label,
.mat-mdc-form-field-error {
  text-align: center,
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-empty {
  text-align: left
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-label {
  transition: transform .4s cubic-bezier(.25, .8, .25, 1), color .4s cubic-bezier(.25, .8, .25, 1), width .4s cubic-bezier(.25, .8, .25, 1) !important
}

.mat-datepicker-toggle {
  color: black !important;
}

.mat-icon {
  height: 1rem !important;
  width: 1rem !important;
}

.material-icons {
  font-size: 1rem !important;
}

.mat-mdc-radio-button {
  margin-top: 1rem;
}

.mat-calendar-table-header tr {
  color: rgba(0, 0, 0, 0.38);
  background-color: white;
}

.mat-calendar-body tr:hover {
  background-color: transparent !important;
  cursor: pointer;
  color: black;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.dotted .mat-mdc-form-field .mat-form-field-underline {
  display: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.dropDownHide .mat-mdc-form-field .mat-mdc-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, -0.46);
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.dropDownHide .mat-mdc-form-field .mat-mdc-select.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.86);
}

.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: $primary-color;
}


.mat-mdc-chip:not(.mat-mdc-basic-chip):focus {
  box-shadow: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.mat-mdc-chip.mat-chip-selected.mat-primary {
  background-color: $primary-color;
  color: white;
}

.mat-mdc-progress-spinner.mat-progress-spinner-indeterminate-animation {
  animation: mat-progress-spinner-linear-rotate 2s linear infinite !important;
}

.mat-mdc-progress-spinner.mat-progress-spinner-indeterminate-animation circle {
  transition-property: stroke !important;
  animation-duration: 4s !important;
  animation-timing-function: cubic-bezier(.35, 0, .25, 1) !important;
  animation-iteration-count: infinite !important;
}

.mat-mdc-menu-content:not(:empty) {
  padding-right: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 8px !important;
  padding-top: 12px !important;
}

.mat-mdc-menu-panel {
  min-width: auto !important;
  max-width: none !important;
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background-color: initial !important;
}

.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]) {
  a {
    outline-width: 5px;
    outline-style: auto;
    outline-color: auto;
  }
}

.mat-mdc-list-base .mat-mdc-list-item,
.mat-list-base .mat-list-option {
  font-size: 14px !important;
  height: 36px !important;
}

.mat-drawer-container {
  background-color: #ffffff !important;
}

// New styles after angular 17 upgrade
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: inherit !important;
}

label {
  margin-bottom: unset !important;
}

:root {
  --mat-form-field-state-layer-color: transparent;
  --mdc-filled-text-field-container-color: transparent;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-form-field-filled-with-label-container-padding-bottom: 0px;
  --mdc-icon-button-icon-size: 14px;
  --mdc-icon-button-state-layer-size:25px;

  .mdc-text-field {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mat-mdc-standard-chip {
    --mdc-chip-label-text-line-height: 21px;
    --mdc-chip-label-text-size: 14px;
    --mdc-chip-label-text-weight: 500;
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-container-height: 16px;
  }
  
  .header {
    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 25px;
    }
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper{
    padding-left: 0px;
  }

  .mdc-text-field--filled.mdc-text-field--disabled {
    background-color: transparent;
  }

  .rb-sec-hdr {
    --mdc-icon-button-icon-size: 20px;
  }

  .rb-sec-hdr-notapply{
    --mdc-icon-button-icon-size: 20px;
  }
  

  .mat-mdc-form-field-icon-suffix{
    align-self: unset;
  }
  
  .mat-datepicker-toggle-default-icon{
    --mdc-icon-button-icon-size: 18px;
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0px;
}

.dottedLine {
  .mdc-text-field {
    background-color: var(--mdc-filled-text-field-disabled-container-color) !important;
    pointer-events: none !important;
  }
}

.mat-typography h4{
	margin-bottom: 0px;
}

.mat-typography h3 {
  font: 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}