$fa-font-path: "../../node_modules/font-awesome/fonts";

$background-color: #fff;
$copyrightbackground-color: #111329;
$selected-metal: white;
$disabled-color: #777777;
$primary-color: rgb(238, 70, 81);
$primary-hover: rgb(238, 70, 81);
$secondary-color: #111329;
$secondary-scolor: #f0ad4e;
$secondary-hover: #111329;
$accept-color: #76d0f5;
$accept-hover: #76d0f5;
$reject-color: #f32314;
$reject-hover: #d43f3a;
$secondary-button-color: #eeeeee;

$body-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
$button-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
$error-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
$primary-font-color: #333333;
$secondary-font-color: #777777;
$title-font-size: 3rem;
$title-font-weight: 400;
$sub-title-font-size: 1.5rem;
$sub-title-font-weight: 400;
$heading-font-size: 2.25rem;
$body-font-size: 1rem;
$copyright-font-size: 10px;
$top-navigation-font-size: 1rem;

//bootstrap
$spacer: 1rem !default;
$spacers: (
	0: 0,
	1: $spacer,
	2: calc(
		$spacer * 2,
	),
	3: calc(
		$spacer * 3,
	),
	4: calc(
		$spacer * 4,
	),
	5: calc(
		$spacer * 5,
	),
	6: calc(
		$spacer * 6,
	),
	7: calc(
		$spacer * 7,
	),
	8: calc(
		$spacer * 8,
	),
	9: calc(
		$spacer * 9,
	),
	10: calc(
		$spacer * 10,
	),
	11: calc(
		$spacer / 5,
	),
	12: calc(
		$spacer / 2
	)
) !default;
